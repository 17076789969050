/*
 * @file
 * Create an analog clock
 */

import Events from "./Events.js";

export default function EventsCard() {
  return (
    <div className="events-card card">
      <div className="card-title">
        <span className="events">Today at MPP</span>
      </div>
      <div className="card-body">
        <Events />
      </div>
    </div>
  );
}
