/*
 * @file
 * Create an analog clock
 */

import DayOfYear from "./DayOfYear.js";
import Calendar from "./Calendar.js";

function CalendarCard() {
  return (
    <div className="calendar-card card">
      <div className="card-title">
        <DayOfYear />
      </div>
      <div className="card-body">
        <Calendar />
      </div>
    </div>
  );
}

export default CalendarCard;
