/*
 * @file
 * Provide a simple span containing the day of the week
 */

import React, { useContext } from "react";
import { DateContext } from "../App.js";

function DayOfYear() {
  const date = useContext(DateContext);

  const dayOfYear = React.useRef(null);

  // Return the name of the current day
  function currentDay() {
    return date.toLocaleString("en-CA", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }

  return (
    <span className="day-of-year" ref={dayOfYear}>
      {currentDay()}
    </span>
  );
}

export default DayOfYear;
