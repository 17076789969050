/*
 * @file
 * Create an analog clock
 */

import Clock from "./Clock.js";
import DayOfWeek from "./DayOfWeek";

function ClockCard() {
  return (
    <div className="clock-card card">
      <div className="card-title">
        <DayOfWeek />
      </div>
      <div className="card-body">
        <Clock />
      </div>
    </div>
  );
}

export default ClockCard;
