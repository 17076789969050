import "./css/style.css";
import ClockCard from "./components/ClockCard.js";
import CalendarCard from "./components/CalendarCard.js";
import EventsCard from "./components/EventsCard.js";
import PhotoCard from "./components/PhotoCard.js";
import { runAtSpecificTimeOfDay } from "./components/utils.js";
import { createContext, useEffect, useState } from "react";

export const DateContext = createContext(new Date());

export default function App() {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Update currentDate once per day at 12:10am
  useEffect(() => {
    runAtSpecificTimeOfDay(0, 10, () => setCurrentDate(new Date()));
  });

  return (
    <DateContext.Provider value={currentDate}>
      <div className="mpp-today">
        <div className="card-container">
          <ClockCard />
          <CalendarCard />
          <EventsCard />
          <PhotoCard />
        </div>
      </div>
    </DateContext.Provider>
  );
}
