/*
 * @file
 * Show a picture drawn from the Matriarch Photo Frame project.
 */

import React, { useState, useEffect } from "react";
import { constants } from "../constants/constants.js";

export default function Photo() {
  const [photoUrl, setPhotoUrl] = useState(null),
    [isTransitioning, setIsTransitioning] = useState(false),
    apiEndpoint = constants.photo.apiEndpoint,
    photoDuration = constants.photo.photoDuration,
    styles = { backgroundImage: `url(${photoUrl})` };

  useEffect(() => {
    fetchPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPhoto();
    }, photoDuration);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`photo-frame ${isTransitioning ? "transitioning" : ""} `}
      style={styles}
    ></div>
  );

  async function fetchPhoto() {
    try {
      const response = await fetch(apiEndpoint);
      if (response.ok) {
        const photoUrl = await response.text();

        // Start the transition
        setIsTransitioning(true);

        // Wait for fade-out transition to complete
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Update photo URL
        setPhotoUrl(photoUrl);

        // End the transition
        setIsTransitioning(false);
      } else {
        console.error("Error fetching photo:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching photo:", error.message);
    }
  }
}
