/*
 *
 */

import React, { useState, useEffect, useContext } from "react";
import { DateContext } from "../App.js";
import Event from "./Event.js";
import { constants } from "../constants/constants.js";

export default function Events() {
  const [events, setEvents] = useState([]),
    [currentPage, setCurrentPage] = useState(0),
    date = useContext(DateContext),
    apiEndpoint = constants.events.apiEndpoint,
    apiFullUrl = apiEndpoint + "?date=" + date.toISOString().split("T")[0],
    itemsPerPage = constants.events.itemsPerPage,
    paginationDuration = constants.events.paginationDuration;

  useEffect(() => {
    fetchEvents(apiFullUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Implement pagination. This also recolors events as a good side effect.
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentPage((currentPage + 1) % Math.ceil(events.length / 5));
    }, paginationDuration);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, events.length]);

  const eventsToShow = events.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const eventItems = eventsToShow.map((event) => (
    <Event key={event.id} event={event} />
  ));

  return (
    <table className="table-primary events">
      <EventHeader />
      <tbody>{eventItems}</tbody>
    </table>
  );

  async function fetchEvents(url) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setEvents(data);
      } else {
        console.error("Error fetching events:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching events:", error.message);
    }
  }

  function EventHeader() {
    return (
      <thead>
        <tr>
          <th scope="col" className="col-2">
            Time
          </th>
          <th scope="col" className="col-7">
            Description
          </th>
          <th scope="col" className="col-7">
            Room
          </th>
        </tr>
      </thead>
    );
  }
}
