/*
 * Declare global constants
 */

export const constants = {
  events: {
    apiEndpoint:
      "https://utilities.saplingadvisors.com/events/api/events-for-date.php",
    itemsPerPage: 5,
    paginationDuration: 8000, // milliseconds
  },
  photo: {
    apiEndpoint:
      "https://matriarch-photo-frame.saplingadvisors.com/api/random-url.php",
    photoDuration: 30000, // milliseconds
  },
};
